import { render } from "./LoadingOverlay.vue?vue&type=template&id=922011c0"
import script from "./LoadingOverlay.vue?vue&type=script&lang=js"
export * from "./LoadingOverlay.vue?vue&type=script&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "922011c0"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('922011c0', script)) {
    api.reload('922011c0', script)
  }
  
  module.hot.accept("./LoadingOverlay.vue?vue&type=template&id=922011c0", () => {
    api.rerender('922011c0', render)
  })

}

script.__file = "src/components/overlays/LoadingOverlay.vue"

export default script